import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { camelizeKeys } from '@backmarket/utils/object/camelizeKeys'

import { getBouyguesAddressAPIValidationErrors } from './getBouyguesAddressAPIValidationErrors'

export const getAPIErrors = (error: Record<string, unknown>) => {
  const i18n = useI18n()

  const errors = {}

  Object.assign(errors, getBouyguesAddressAPIValidationErrors(error, i18n))

  const camelizedError = camelizeKeys(error)

  // FIXME Needs to update the httpApiError to manage endpointSettings.transformResponseToCamelCase
  Object.assign(
    errors,
    Object.keys(camelizedError).reduce(
      (acc, key) => ({
        ...acc,
        [key]:
          Array.isArray(camelizedError[key]) &&
          (camelizedError[key] as Array<string>).length > 0
            ? (camelizedError[key] as Array<string>)[0]
            : '',
      }),
      {},
    ),
  )

  return errors
}

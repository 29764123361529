import type { ProblemValidationErrorMember } from '@backmarket/http-api/src/api-models/ProblemValidationErrorMember'
import { HttpApiError } from '@backmarket/http-api/src/utils/HttpApiError'

type GetAPIValidationErrorsOptions = {
  targetToFieldIdMapper?: (apiTarget: string) => string
  getLocalizedErrorMessage?: (
    error: ProblemValidationErrorMember,
  ) => string | undefined
}

/**
 * Transform an HttpApiError instance into a localized record of fieldId => errorMessage
 */
export const getAPIValidationErrors = (
  error: unknown,
  options: GetAPIValidationErrorsOptions = {},
): Record<string, string> => {
  const {
    targetToFieldIdMapper = (apiTarget) => apiTarget,
    getLocalizedErrorMessage,
  } = options

  if (error instanceof HttpApiError) {
    return error.errors.reduce((acc, validationError) => {
      return {
        ...acc,
        [targetToFieldIdMapper(validationError.target)]:
          getLocalizedErrorMessage?.(validationError) ?? validationError.title,
      }
    }, {})
  }

  return {}
}

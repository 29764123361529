import type { ProblemValidationErrorMember } from '@backmarket/http-api'
import type { I18n } from '@backmarket/nuxt-module-i18n/types'

import { getAPIValidationErrors } from './getAPIValidationErrors'
import translations from './getBouyguesAddressAPIValidationErrors.translations'

export const getBouyguesAddressAPIValidationErrors = (
  error: unknown,
  i18n: I18n,
) => {
  const getTranslationDefinition = (
    validationError: ProblemValidationErrorMember,
  ) => {
    switch (validationError.type) {
      case '/errors/invalid-locality':
        return translations.invalidCity
      case '/errors/invalid-post-code':
        return translations.invalidZipCode
      case '/errors/invalid-street-number':
      case '/errors/out-of-bounds-street-number':
        return translations.invalidStreetNumber
      case '/errors/invalid-lieu-dit':
      case '/errors/invalid-street-name':
        return translations.invalidStreet
      case 'errors/country-not-allowed':
        return translations.countryNotAllowed
      case '/errors/missing':
      case 'errors/missing-last-name':
      case 'errors/missing-first-name':
        return translations.required
      default:
        return undefined
    }
  }

  return getAPIValidationErrors(error, {
    targetToFieldIdMapper: (target) => {
      switch (target) {
        case 'body.addressLine1':
          return 'street'
        case 'body.postCode':
          return 'postalCode'
        default:
          return target.replace('body.', '')
      }
    },
    getLocalizedErrorMessage: (validationError) => {
      const definition = getTranslationDefinition(validationError)

      return definition ? i18n(definition) : undefined
    },
  })
}

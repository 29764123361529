export default {
  required: {
    id: 'address_fields_partner_required',
    defaultMessage: 'This field is required',
  },
  countryNotAllowed: {
    id: 'address_fields_partner_country_not_allowed',
    defaultMessage: "Partner's offers are not available in this country",
  },
  invalidStreet: {
    id: 'address_fields_partner_invalid_street_name',
    defaultMessage: 'This street is not recognized by our system',
  },
  invalidStreetNumber: {
    id: 'address_fields_partner_invalid_street_number',
    defaultMessage: 'This street number is not recognized by our system',
  },
  invalidCity: {
    id: 'address_fields_partner_invalid_city',
    defaultMessage: 'This city is not recognized by our system',
  },
  invalidZipCode: {
    id: 'address_fields_partner_invalid_zip_code',
    defaultMessage: 'This ZIP code is not recognized by our system',
  },
}
